import React from 'react'
import {withRouter} from 'react-router'

class Window extends React.Component {
  constructor(props) {
    super(props)

    this.title = React.createRef()
  }

  getInputValue(name) {
    return this[name].current.value
  }

  getEndpoint() {
    return 'https://haspa.fp-eauthor.fp-server.com/cms'
  }

  async addWbt(args) {
    var response2 = await fetch(
      this.getEndpoint() + '/api/collections/save/wbts',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            type: 'wbt_template',
            title: args.title,
            created_by: args.created_by,
            content: args.wbt
          }
        })
      }
    ).then(res => res.json())
    this.props.onCloseModal()
  }

  handleAddWbt(e) {
    e.preventDefault()

    this.addWbt({
      id: this.props.wbtId,
      wbt: this.props.modalArgs.wbt,
      title: this.getInputValue('title'),
      created_by: JSON.parse(sessionStorage.getItem('userId')),
    })
  }

  render() {
    return(
      <div className="modal-window">
        <div className="headline">WBT als Vorlage speichern</div>
        <div className="main">
          <form
            className="form"
            onSubmit={this.handleAddWbt.bind(this)}
          >
            <input
              ref={this.title}
              type="text"
              placeholder="Vorlage Titel"
            />
            <button className="button highlighted round" type="submit">
              <div className="label">Speichern</div>
            </button>
          </form>
        </div>
        <button
          className="close-modal"
          onClick={this.props.onCloseModal}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25.223" height="25.223" viewBox="0 0 25.223 25.223">
            <path d="M0,10.165H7.671v7.671h2.4V10.165h7.765v-2.4H10.071V0h-2.4V7.765H0Z" transform="translate(12.611 0) rotate(45)" />
          </svg>
        </button>
      </div>
    )
  }
}

export default withRouter(Window)
